<template>
	<div>
     <div class="container" id="order">
        <nav class="navbar" id="Menu-back">
            <div class="container">
              <div class="router-button" id="button1" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-1', toTabHeader: 'Спортивный инвентарь' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <div class="router-button" id="button2" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-2', toTabHeader: 'Мебель' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <div class="router-button" id="button3" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-3', toTabHeader: 'Малые архитектурные формы' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <div class="router-button" id="button4" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-4', toTabHeader: 'Трибуны зрительские' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <div class="router-button" id="button5" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-5', toTabHeader: 'Заборы и ограждения' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <div class="router-button" id="button6" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-6', toTabHeader: 'Различные услуги' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <div class="router-button" id="button7" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-7', toTabHeader: 'Оборудование на заказ' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <div class="router-button" id="button8" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-8', toTabHeader: 'Элементы интерьера' }}">
                    <button class="orange-button desktop" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                    <img data-src="img/back.svg" class="lazyload mobile">
                </router-link> 
              </div>
              <img data-src="img/logo.svg" class="lazyload navbar__logo navbar__logo_pad">
              <a href="tel:+77017859800" class="navbar__phone-href navbar-phone-order d-none d-md-block">
                <i class="fas fa-phone"></i>
                +7 (701) 785-80-90
              </a>
              <a href="tel:+77017858090" class="navbar__phone-href d-block d-md-none">
                  <i class="fas fa-phone"></i>
              </a>
            </div>
        </nav>
        <div class="col-md-12">
            <div class="router-button" id="button1" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-1', toTabHeader: 'Спортивный инвентарь' }}">
                    <button class="orange-button" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                </router-link> 
            </div>
            <div class="router-button" id="button2" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-2', toTabHeader: 'Корпусная мебель' }}">
                    <button class="orange-button" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                </router-link> 
            </div>
            <div class="router-button" id="button3" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-3', toTabHeader: 'Малые архитектурные формы' }}">
                    <button class="orange-button" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                </router-link> 
            </div>
            <div class="router-button" id="button5" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-5', toTabHeader: 'Заборы и ограждения' }}">
                    <button class="orange-button" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                </router-link> 
            </div>
            <div class="router-button" id="button6" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-6', toTabHeader: 'Различные услуги' }}">
                    <button class="orange-button" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                </router-link> 
            </div>
            <div class="router-button" id="button7" style="display: none;">
                <router-link :to="{ name: 'Home', query: { fromOrder: true, toTab: 'c-7', toTabHeader: 'Оборудование на заказ' }}">
                    <button class="orange-button" style="padding: 0.7rem 1.4rem;">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
                </router-link> 
            </div>
            <h2 id="order-header"></h2>
        </div>
       
           <div class="row" style="align-items: start">
             <div class="col-md-12">	                                        
                <div class="subcategory-sizetable container" id="c-1-1">
                   <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/basket-order1.jpg" class="lazyload" alt="Щит баскетбольный">
                        <p>Щит баскетбольный A-01</p>
                        <button class="orange-button " @click="openModelWindow('Щит баскетбольный A-01')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/basket-order2.jpg" class="lazyload" alt="Щит баскетбольный">
                        <p>Щит баскетбольный A-02</p>
                        <button class="orange-button " @click="openModelWindow('Щит баскетбольный A-02')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/basket-order3.jpg" class="lazyload" alt="Щит баскетбольный">
                        <p>Щит баскетбольный A-03</p>
                        <button class="orange-button " @click="openModelWindow('Щит баскетбольный A-03')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/basket-order4.jpg" class="lazyload" alt="Щит баскетбольный">
                        <p>Щит баскетбольный A-04</p>
                        <button class="orange-button " @click="openModelWindow('Щит баскетбольный A-04')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/basket-order5.jpg" class="lazyload" alt="Щит баскетбольный">
                        <p>Щит баскетбольный A-05</p>
                        <button class="orange-button " @click="openModelWindow('Щит баскетбольный A-05')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/basket-order6.jpg" class="lazyload" alt="Щит баскетбольный">
                        <p>Щит баскетбольный A-06</p>
                        <button class="orange-button " @click="openModelWindow('Щит баскетбольный A-06')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/basket-order7.jpg" class="lazyload" alt="Щит баскетбольный">
                        <p>Щит баскетбольный A-07</p>
                        <button class="orange-button " @click="openModelWindow('Щит баскетбольный A-07')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                  </div>
               </div>
              
            <div class="subcategory-sizetable container" id="c-1-3">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport1.jpg" class="lazyload" alt="футбольный тренажер">
                      <p>Футбольный <br class="d-none d-md-block">тренажер</p>
                      <button class="orange-button " @click="openModelWindow('Футбольный тренажер')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport2.jpg" class="lazyload" alt="Брусья для отжиманий паралетсы">
                      <p>Брусья для отжиманий паралетсы</p>
                      <button class="orange-button " @click="openModelWindow('Брусья для отжиманий паралетсы')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport3.jpg" class="lazyload" alt="рукоход уличный">
                      <p>Рукоход <br class="d-none d-md-block">уличный</p>
                      <button class="orange-button " @click="openModelWindow('Рукоход уличный')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport4.jpg" class="lazyload" alt="бесконечный рукоход">
                      <p>Турник А-01</p>
                      <button class="orange-button " @click="openModelWindow('Турник А-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport5.jpg" class="lazyload" alt="настенный турник">
                      <p>Турник А-02</p>
                      <button class="orange-button " @click="openModelWindow('Турник А-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport6.jpg" class="lazyload" alt="настенный турник">
                      <p>Турник А-03</p>
                      <button class="orange-button " @click="openModelWindow('Турник А-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport7.jpg" class="lazyload" alt="Домашняя шведская стенка">
                      <p>Домашняя шведская стенка</p>
                      <button class="orange-button " @click="openModelWindow('Домашняя шведская стенка')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport8.jpg" class="lazyload" alt="Уличный спортивный тренажер">
                      <p>Уличный спортивный тренажер</p>
                      <button class="orange-button " @click="openModelWindow('Уличный спортивный тренажер')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sport9.jpg" class="lazyload" alt="Уличная шведская стенка с турником и брусьями">
                      <p>Уличная шведская стенка</p>
                      <button class="orange-button " @click="openModelWindow('Уличная шведская стенка с турником и брусьями')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-1-4">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                        <img data-src="img/workout-order1.jpg" class="lazyload" alt="брусья уличные">
                        <p>Брусья стандартные</p>
                        <button class="orange-button " @click="openModelWindow('Брусья стандартные')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/workout-order2.jpg" class="lazyload" alt="уличный турник">
                      <p>Тройной турник </p>
                      <button class="orange-button " @click="openModelWindow('Тройной турник')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                        <img data-src="img/workout-order4.jpg" class="lazyload" alt="брусья двойные">
                        <p>Брусья двойные</p>
                        <button class="orange-button " @click="openModelWindow('Брусья двойные')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/workout-order3.jpg" class="lazyload" alt="воркаут комплекс">
                      <p>Воркаут комплекс A-01</p>
                      <button class="orange-button " @click="openModelWindow('Воркаут комплекс A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                        <img data-src="img/workout-order5.jpg" class="lazyload" alt="шведская стенка с турником и брусьями">
                        <p>Воркаут комплекс A-02</p>
                        <button class="orange-button " @click="openModelWindow('Воркаут комплекс A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                        <img data-src="img/workout-order6.jpg" class="lazyload" alt="шведская стенка с турником и брусьями">
                        <p>Воркаут комплекс A-03</p>
                        <button class="orange-button " @click="openModelWindow('Воркаут комплекс A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-1-5">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/football-geit1.jpg" class="lazyload" alt="Профессиональные футбольные ворота">
                      <p>Профессиональные футбольные ворота</p>
                      <button class="orange-button " @click="openModelWindow('Профессиональные футбольные ворота')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/football-geit2.jpg" class="lazyload" alt="Мини-футбольные ворота">
                      <p>Мини-футбольные <br class="d-none d-md-block">ворота</p>
                      <button class="orange-button " @click="openModelWindow('Мини-футбольные ворота')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/football-geit3.jpg" class="lazyload" alt="Юниорские футбольные ворота">
                      <p>Юниорские футбольные <br class="d-none d-md-block">ворота</p>
                      <button class="orange-button " @click="openModelWindow('Юниорские футбольные ворота')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/football-geit4.jpg" class="lazyload" alt="Мини-футбольные ворота с щитом">
                      <p>Мини-футбольные ворота с щитом</p>
                      <button class="orange-button " @click="openModelWindow('Мини-футбольные ворота с щитом')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-1-6">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tennis1.jpg" class="lazyload" alt="деревянный теннисный стол">
                      <p>Теннисный стол A-01</p>
                      <button class="orange-button " @click="openModelWindow('Теннисный стол A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tennis2.jpg" class="lazyload" alt="железный теннисный стол">
                    <p>Теннисный стол A-02</p>
                    <button class="orange-button " @click="openModelWindow('Теннисный стол A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tennis3.jpg" class="lazyload" alt="деревянный теннисный стол">
                      <p>Теннисный стол A-03</p>
                      <button class="orange-button " @click="openModelWindow('Теннисный стол A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tennis4.jpg" class="lazyload" alt="домашний теннисный стол">
                      <p>Теннисный стол A-04</p>
                      <button class="orange-button " @click="openModelWindow('Теннисный стол A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tennis5.jpg" class="lazyload" alt="уличный теннисный стол">
                      <p>Теннисный стол A-05</p>
                      <button class="orange-button " @click="openModelWindow('Теннисный стол A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
               <div class="subcategory-sizetable container" id="c-2-1">
                  <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office1.jpg" class="lazyload" alt="офисный шкаф">
                        <p>Шкаф A-01</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-01')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office2.jpg" class="lazyload" alt="офисный шкаф">
                        <p>Шкаф A-02</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-02')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office3.jpg" class="lazyload" alt="офисный шкаф">
                        <p>Шкаф A-03</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-03')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office4.jpg" class="lazyload" alt="стеллаж угловой">
                        <p>Шкаф A-04</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-04')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office5.jpg" class="lazyload" alt="угловой шкаф">
                        <p>Шкаф A-05</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-05')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office6.jpg" class="lazyload" alt="шкаф для книг">
                        <p>Шкаф A-06</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-06')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                </div>
                 <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office7.jpg" class="lazyload" alt="стеллаж">
                        <p>Шкаф A-07</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф< A-07')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office8.jpg" class="lazyload" alt="стеллаж">
                        <p>Шкаф A-08</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-08')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office9.jpg" class="lazyload" alt="шкаф для книг">
                        <p>Шкаф A-09</p>
                        <button class="orange-button " @click="openModelWindow('Шкаф A-09')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office10.jpg" class="lazyload" alt="лофт  стол">
                        <p>Стол A-01</p>
                        <button class="orange-button " @click="openModelWindow('Стол A-01')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office11.jpg" class="lazyload" alt="офисный стол">
                        <p>Стол A-02</p>
                        <button class="orange-button " @click="openModelWindow('Стол A-02')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office12.jpg" class="lazyload" alt="офисный стол">
                        <p>Стол A-03</p>
                        <button class="orange-button " @click="openModelWindow('Стол A-03')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office13.jpg" class="lazyload" alt="офисный стол">
                        <p>Стол A-04</p>
                        <button class="orange-button " @click="openModelWindow('Стол A-04')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office14.jpg" class="lazyload" alt="лофт стол">
                        <p>Стол A-05</p>
                        <button class="orange-button " @click="openModelWindow('Стол A-05')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/office15.jpg" class="lazyload" alt="письменный стол">
                        <p>Стол A-06</p>
                        <button class="orange-button " @click="openModelWindow('Стол A-06')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-2-2">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport1.jpg" class="lazyload" alt="шкафчики для раздевалки">
                      <p>Шкафчики A-01</p>
                      <button class="orange-button " @click="openModelWindow('Шкафчики A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport2.jpg" class="lazyload" alt="шкафчики для раздевалки">
                      <p>Шкафчики A-02</p>
                      <button class="orange-button" @click="openModelWindow('Шкафчики A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport3.jpg" class="lazyload" alt="шкафчики для раздевалки">
                      <p>Шкафчики A-03</p>
                      <button class="orange-button " @click="openModelWindow('Шкафчики A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport4.jpg" class="lazyload" alt="шкафчики для раздевалки">
                      <p>Шкафчики A-04</p>
                      <button class="orange-button " @click="openModelWindow('Шкафчики A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport5.jpg" class="lazyload" alt="шкафчики для раздевалки">
                      <p>Шкафчики A-05</p>
                      <button class="orange-button" @click="openModelWindow('Шкафчики A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport6.jpg" class="lazyload" alt="шкафчики для раздевалки">
                      <p>Шкафчики A-06</p>
                      <button class="orange-button " @click="openModelWindow('Шкафчики A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport7.jpg" class="lazyload" alt="Скамейки для спортзала">
                      <p>Скамейки для спортзала A-01</p>
                      <button class="orange-button " @click="openModelWindow('Скамейки для спортзала A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport8.jpg" class="lazyload" alt="Скамейки для спортзала">
                      <p>Скамейки для спортзала A-02</p>
                      <button class="orange-button " @click="openModelWindow('Скамейки для спортзала A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport9.jpg" class="lazyload" alt="Скамейки для спортзала">
                      <p>Скамейки для спортзала A-03</p>
                      <button class="orange-button " @click="openModelWindow('Скамейки для спортзала A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport10.jpg" class="lazyload" alt="Вешалка со скамейкой для спортзала">
                      <p>Вешалка для спортзала A-01</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка для спортзала A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport11.jpg" class="lazyload" alt="Вешалка со скамейкой для спортзала">
                      <p>Вешалка для спортзала A-02</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка для спортзала A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport12.jpg" class="lazyload" alt="Вешалка со скамейкой для спортзала">
                      <p>Вешалка для спортзала A-03</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка для спортзала A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sport13.jpg" class="lazyload" alt="Вешалка со скамейкой для спортзала">
                      <p>Вешалка для спортзала A-04</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка для спортзала A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>    
               </div>
               <div class="subcategory-sizetable container" id="c-2-3">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sad1.jpg" class="lazyload" alt="качели скамейки для сада">
                      <p>Качели A-01</p>
                      <button class="orange-button " @click="openModelWindow('Качели A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sad2.jpg" class="lazyload" alt="качели скамейки для сада">
                      <p>Качели A-02</p>
                      <button class="orange-button " @click="openModelWindow('Качели A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sad3.jpg" class="lazyload" alt="скамейки для сада">
                      <p>Скамейка B-01</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка B-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sad4.jpg" class="lazyload" alt="качели скамейки для сада">
                      <p>Качели A-03</p>
                      <button class="orange-button " @click="openModelWindow('Качели A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sad5.jpg" class="lazyload" alt="Гамак для дачи">
                      <p>Гамак для дачи</p>
                      <button class="orange-button " @click="openModelWindow('Гамак для дачи')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>                                        
               </div>
            <div class="subcategory-sizetable container" id="c-2-4">
              <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft1.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-01</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft2.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-02</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft3.jpg" class="lazyload" alt="вешалка с камодом лофт">
                      <p>Вешалка B-01</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка B-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft4.jpg" class="lazyload" alt="шкаф лофт">
                      <p>Шкаф C-01</p>
                      <button class="orange-button " @click="openModelWindow('Шкаф C-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft5.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-03</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft6.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-04</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft7.jpg" class="lazyload" alt="стол лофт">
                      <p>Стол B-01</p>
                      <button class="orange-button " @click="openModelWindow('Стол B-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft8.jpg" class="lazyload" alt="стол лофт">
                      <p>Стол B-02</p>
                      <button class="orange-button " @click="openModelWindow('Стол B-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft9.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-05</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft10.jpg" class="lazyload" alt="стол лофт">
                      <p>Стол B-03</p>
                      <button class="orange-button " @click="openModelWindow('Стол B-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft11.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-06</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft12.jpg" class="lazyload" alt="барный стол лофт">
                      <p>Стол B-04</p>
                      <button class="orange-button " @click="openModelWindow('Стол B-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft13.jpg" class="lazyload" alt="полки лофт">
                      <p>Полка A-07</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft14.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-08</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft15.jpg" class="lazyload" alt="вешалка лофт">
                      <p>Вешалка B-02</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка B-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft16.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-09</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft17.jpg" class="lazyload" alt="гардероб лофт">
                      <p>Вешалка B-03</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка B-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-loft18.jpg" class="lazyload" alt="стеллаж лофт">
                      <p>Полка A-010</p>
                      <button class="orange-button " @click="openModelWindow('Полка A-010')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-2-5">
              <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool1.jpg" class="lazyload" alt="стулья металлические лфот ">
                      <p>Стулья A-01</p>
                      <button class="orange-button " @click="openModelWindow('Стулья A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool2.jpg" class="lazyload" alt="стулья лофт">
                      <p>Стулья A-02</p>
                      <button class="orange-button " @click="openModelWindow('Стулья A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool3.jpg" class="lazyload" alt="барные металлические стулья">
                      <p>Барный стул A-03</p>
                      <button class="orange-button " @click="openModelWindow('С A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool4.jpg" class="lazyload" alt="барный металлический стул лофт">
                      <p>Барный стул A-04</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool5.jpg" class="lazyload" alt="барный металлический стул лофт">
                      <p>Барный стул A-05</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool6.jpg" class="lazyload" alt="барный металлический стул">
                      <p>Барный стул A-06</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool7.jpg" class="lazyload" alt="барный металлический стул">
                      <p>Барный стул A-07</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool8.jpg" class="lazyload" alt="барный металлический стул лофт">
                      <p>Барный стул A-08</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool9.jpg" class="lazyload" alt="барный металлический стул лофт">
                      <p>Барный стул A-09</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool10.jpg" class="lazyload" alt="барный металлический стул лофт">
                      <p>Барный стул A-010</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-010')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool11.jpg" class="lazyload" alt="барный металлический стул лофт">
                      <p>Барный стул A-011</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-011')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-stool12.jpg" class="lazyload" alt="барный металлический стул лофт">
                      <p>Барный стул A-012</p>
                      <button class="orange-button " @click="openModelWindow('Барный стул A-012')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-2-6">
              <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table1.jpg" class="lazyload" alt="металлический стол">
                      <p>Стол A-01</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table2.jpg" class="lazyload" alt="металлический стол">
                      <p>Стол A-02</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table3.jpg" class="lazyload" alt="стол в стиле лофт">
                      <p>Стол A-03</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table4.jpg" class="lazyload" alt="стол в стиле лофт">
                      <p>Стол A-04</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table5.jpg" class="lazyload" alt="металлический стол">
                      <p>Стол A-05</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table6.jpg" class="lazyload" alt="стол в стиле лофт">
                      <p>Стол A-06</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table7.jpg" class="lazyload" alt="стол в стиле лофт">
                      <p>Стол A-07</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table8.jpg" class="lazyload" alt="стол в стиле лофт">
                      <p>Стол A-08</p>
                      <button class="orange-button " @click="openModelWindow('Стол A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table9.jpg" class="lazyload" alt="барный стол">
                      <p>Барный-стол A-01</p>
                      <button class="orange-button " @click="openModelWindow('Барный-стол A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table10.jpg" class="lazyload" alt="Журнальный столик">
                      <p>Журнальный столик A-01</p>
                      <button class="orange-button " @click="openModelWindow('Журнальный столик A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table11.jpg" class="lazyload" alt="Журнальный столик лофт">
                      <p>Журнальный столик A-02</p>
                      <button class="orange-button " @click="openModelWindow('Журнальный столик A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-table12.jpg" class="lazyload" alt="Журнальный столик лофт">
                      <p>Журнальный столик A-03</p>
                      <button class="orange-button " @click="openModelWindow('Журнальный столик A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-2-7">
              <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa1.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван A-01</p>
                      <button class="orange-button " @click="openModelWindow('Диван A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa2.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван A-02</p>
                      <button class="orange-button " @click="openModelWindow('Диван A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa3.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван A-03</p>
                      <button class="orange-button " @click="openModelWindow('Диван A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa4.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван A-04</p>
                      <button class="orange-button " @click="openModelWindow('Диван A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa5.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван A-05</p>
                      <button class="orange-button " @click="openModelWindow('Диван A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa6.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван C-06</p>
                      <button class="orange-button " @click="openModelWindow('Диван C-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa7.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван A-07</p>
                      <button class="orange-button " @click="openModelWindow('Диван A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa8.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван A-08</p>
                      <button class="orange-button " @click="openModelWindow('Диван A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-sofa9.jpg" class="lazyload" alt="диван в стиле лофт">
                      <p>Диван C-09</p>
                      <button class="orange-button " @click="openModelWindow('Диван C-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-2-8">
              <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed1.jpg" class="lazyload" alt="кровать в стиле лофт">
                      <p>Кровать A-01</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed2.jpg" class="lazyload" alt="кровать в стиле лофт">
                      <p>Кровать A-02</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed3.jpg" class="lazyload" alt="кровать для спальни">
                      <p>Кровать A-03</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed4.jpg" class="lazyload" alt="кровать в стиле лофт">
                      <p>Кровать A-04</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed5.jpg" class="lazyload" alt="кровать в стиле лофт">
                      <p>Кровать A-05</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed6.jpg" class="lazyload" alt="кровать в стиле лофт">
                      <p>Кровать A-06</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed7.jpg" class="lazyload" alt="двухъярусная кровать в стиле лофт">
                      <p>Кровать A-07</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed8.jpg" class="lazyload" alt="двухъярусная кровать">
                      <p>Кровать A-08</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/meb-bed9.jpg" class="lazyload" alt="двухъярусная кровать в стиле лофт">
                      <p>Кровать A-09</p>
                      <button class="orange-button " @click="openModelWindow('Кровать A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-3-1">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/besedka1.jpg" class="lazyload" alt="беседка из поликарбоната">
                      <p>Беседка A-01</p>
                      <button class="orange-button " @click="openModelWindow('Беседка A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/besedka2.jpg" class="lazyload" alt="беседка для сада">
                      <p>Беседка A-02</p>
                      <button class="orange-button " @click="openModelWindow('Беседка A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/besedka3.jpg" class="lazyload" alt="кованная беседка">
                      <p>Беседка A-03</p>
                      <button class="orange-button " @click="openModelWindow('Беседка A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/besedka4.jpg" class="lazyload" alt="беседка в стиле лофт">
                      <p>Беседка A-04</p>
                      <button class="orange-button " @click="openModelWindow('Беседка A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/besedka5.jpg" class="lazyload" alt="Беседка садовая из профлиста">
                      <p>Беседка A-05</p>
                      <button class="orange-button " @click="openModelWindow('Беседка A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/besedka6.jpg" class="lazyload" alt="беседка из поликарбоната ">
                      <p>Беседка A-06</p>
                      <button class="orange-button " @click="openModelWindow('Беседка A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 block-order">
                      <div class="ficha">
                        <img data-src="img/besedka7.jpg" class="lazyload" alt="беседка дворовая металлическая">
                        <p>Беседка A-07</p>
                        <button class="orange-button " @click="openModelWindow('Беседка A-07')">УЗНАТЬ ЦЕНУ</button>
                      </div>
                    </div>
                  </div>
                </div>
           
            <div class="subcategory-sizetable container" id="c-3-2">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/urni-order1.jpg" class="lazyload">
                      <p>Урна A-01</p>
                      <button class="orange-button " @click="openModelWindow('Урна A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/urni-order2.jpg" class="lazyload">
                      <p>Урна A-02</p>
                      <button class="orange-button " @click="openModelWindow('Урна A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/urni-order3.jpg" class="lazyload">
                      <p>Урна A-03</p>
                      <button class="orange-button " @click="openModelWindow('Урна A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/urni-order4.jpg" class="lazyload">
                      <p>Урна A-04</p>
                      <button class="orange-button " @click="openModelWindow('Урна A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/urni-order5.jpg" class="lazyload">
                      <p>Урна A-05</p>
                      <button class="orange-button " @click="openModelWindow('Урна A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-3-3">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/vazon-order1.jpg" class="lazyload">
                      <p>Вазон A-01</p>
                      <button class="orange-button " @click="openModelWindow('Вазон A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/vazon-order2.jpg" class="lazyload">
                      <p>Вазон A-02</p>
                      <button class="orange-button " @click="openModelWindow('Вазон A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                   <div class="ficha">
                      <img data-src="img/vazon-order3.jpg" class="lazyload">
                      <p>Вазон A-03</p>
                      <button class="orange-button " @click="openModelWindow('Вазон A-03')">УЗНАТЬ ЦЕНУ</button>
                   </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/vazon-order4.jpg" class="lazyload">
                      <p>Вазон A-04</p>
                      <button class="orange-button " @click="openModelWindow('Вазон A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/vazon-order5.jpg" class="lazyload">
                      <p>Вазон A-05</p>
                      <button class="orange-button " @click="openModelWindow('Вазон A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                   <div class="ficha">
                      <img data-src="img/vazon-order6.jpg" class="lazyload">
                      <p>Вазон A-06</p>
                      <button class="orange-button " @click="openModelWindow('Вазон A-06')">УЗНАТЬ ЦЕНУ</button>
                   </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-3-4">
               <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order1.jpg" class="lazyload">
                      <p>Скамейка A-01</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order2.jpg" class="lazyload">
                      <p>Скамейка A-02</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                   <div class="ficha">
                      <img data-src="img/ska-order3.jpg" class="lazyload">
                      <p>Скамейка A-03</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-03')">УЗНАТЬ ЦЕНУ</button>
                   </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order4.jpg" class="lazyload">
                      <p>Скамейка A-04</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order5.jpg" class="lazyload">
                      <p>Скамейка A-05</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order6.jpg" class="lazyload">
                      <p>Скамейка A-06</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order7.jpg" class="lazyload">
                      <p>Скамейка A-07</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order8.jpg" class="lazyload">
                      <p>Скамейка A-08</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order9.jpg" class="lazyload">
                      <p>Скамейка A-09</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order10.jpg" class="lazyload">
                      <p>Скамейка A-010</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-010')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order11.jpg" class="lazyload">
                      <p>Скамейка A-011</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-011')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order12.jpg" class="lazyload">
                      <p>Скамейка A-012</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-012')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order13.jpg" class="lazyload">
                      <p>Скамейка A-013</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-013')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order14.jpg" class="lazyload">
                      <p>Скамейка A-014</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-014')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/ska-order15.jpg" class="lazyload">
                      <p>Скамейка A-015</p>
                      <button class="orange-button " @click="openModelWindow('Скамейка A-015')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-3-5">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/post1.jpg" class="lazyload">
                      <p>Пост охраны A-01</p>
                      <button class="orange-button " @click="openModelWindow('Пост охраны A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/post2.jpg" class="lazyload">
                      <p>Пост охраны A-02</p>
                      <button class="orange-button " @click="openModelWindow('Пост охраны A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/post3.jpg" class="lazyload">
                      <p>Пост охраны A-03</p>
                      <button class="orange-button " @click="openModelWindow('Пост охраны A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/post4.jpg" class="lazyload">
                      <p>Пост охраны A-04</p>
                      <button class="orange-button " @click="openModelWindow('Пост охраны A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/post5.jpg" class="lazyload">
                      <p>Пост охраны A-05</p>
                      <button class="orange-button " @click="openModelWindow('Пост охраны A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-3-6">
              <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/chil1.jpg" class="lazyload">
                      <p>Игровой холм</p>
                      <button class="orange-button " @click="openModelWindow('Игровой холм')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/chil2.jpg" class="lazyload">
                      <p>Песочница</p>
                      <button class="orange-button " @click="openModelWindow('Песочница')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/chil3.jpg" class="lazyload">
                      <p>Качели</p>
                      <button class="orange-button " @click="openModelWindow('Качели')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/chil4.jpg" class="lazyload">
                      <p>Детский комплекс</p>
                      <button class="orange-button " @click="openModelWindow('Детский комплекс')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/chil5.jpg" class="lazyload">
                      <p>Качели балансир</p>
                      <button class="orange-button " @click="openModelWindow('Качели балансир')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subcategory-sizetable container" id="c-3-7">
              <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order1.jpg" class="lazyload">
                      <p>Навес A-01</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order2.jpg" class="lazyload">
                      <p>Навес A-02</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order3.jpg" class="lazyload">
                      <p>Навес A-03</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order4.jpg" class="lazyload">
                      <p>Навес A-04</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order5.jpg" class="lazyload">
                      <p>Навес A-05</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order6.jpg" class="lazyload">
                      <p>Навес A-06</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order7.jpg" class="lazyload">
                      <p>Навес A-07</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order8.jpg" class="lazyload">
                      <p>Навес A-08</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order9.jpg" class="lazyload">
                      <p>Навес A-09</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order10.jpg" class="lazyload">
                      <p>Навес A-010</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-010')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order11.jpg" class="lazyload">
                      <p>Навес A-011</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-011')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/naves-order12.jpg" class="lazyload">
                      <p>Навес A-012</p>
                      <button class="orange-button " @click="openModelWindow('Навес A-012')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
            </div>
              <div class="subcategory-sizetable container" id="c-4-1">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-no1.jpg" class="lazyload">
                      <p>Трибуна скамейка A-01</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна скамейка A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-no2.jpg" class="lazyload">
                      <p>Трибуна скамейка A-02</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна скамейка A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-no3.jpg" class="lazyload">
                      <p>Трибуна скамейка A-03</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна скамейка A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-no4.jpg" class="lazyload">
                      <p>Трибуна скамейка A-05</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна скамейка A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-no5.jpg" class="lazyload">
                      <p>Трибуна скамейка A-06</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна скамейка A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-no6.jpg" class="lazyload">
                      <p>Трибуна скамейка A-06</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна скамейка A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="subcategory-sizetable container" id="c-4-2">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-plastic1.jpg" class="lazyload">
                      <p>Трибуна зрительская A-01</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-plastic2.jpg" class="lazyload">
                      <p>Трибуна зрительская A-02</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-plastic3.jpg" class="lazyload">
                      <p>Трибуна зрительская A-03</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-plastic4.jpg" class="lazyload">
                      <p>Трибуна зрительская A-04</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-plastic5.jpg" class="lazyload">
                      <p>Трибуна зрительская A-05</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-plastic6.jpg" class="lazyload">
                      <p>Трибуна зрительская A-06</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="subcategory-sizetable container" id="c-4-3">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-nav1.jpg" class="lazyload">
                      <p>Трибуна с навесом A-01</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна с навесом A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-nav2.jpg" class="lazyload">
                      <p>Трибуна с навесом A-02</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна с навесом A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-nav3.jpg" class="lazyload">
                      <p>Трибуна с навесом A-03</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна с навесом A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-nav4.jpg" class="lazyload">
                      <p>Трибуна с навесом A-04</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна с навесом A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-nav5.jpg" class="lazyload">
                      <p>Трибуна с навесом A-05</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна с навесом A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="subcategory-sizetable container" id="c-4-4">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-order1.jpg" class="lazyload">
                      <p>Трибуна зрительская B-01</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская B-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-order2.jpg" class="lazyload">
                      <p>Трибуна зрительская B-02</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская B-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-order3.jpg" class="lazyload">
                      <p>Трибуна зрительская B-03</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская B-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-order4.jpg" class="lazyload">
                      <p>Трибуна зрительская B-04</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская B-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/tribune-order5.jpg" class="lazyload">
                      <p>Трибуна зрительская B-05</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна зрительская B-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
              </div>
               <div class="subcategory-sizetable container" id="c-5-1">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sports-fence1.jpg" class="lazyload">
                      <p>Спортивное ограждение A-01</p>
                      <button class="orange-button " @click="openModelWindow('Спортивное ограждение A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sports-fence2.jpg" class="lazyload">
                      <p>Спортивное ограждение A-02</p>
                      <button class="orange-button " @click="openModelWindow('Спортивное ограждение A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sports-fence3.jpg" class="lazyload">
                      <p>Спортивное ограждение A-03</p>
                      <button class="orange-button " @click="openModelWindow('Спортивное ограждение A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sports-fence4.jpg" class="lazyload">
                      <p>Спортивное ограждение A-04</p>
                      <button class="orange-button " @click="openModelWindow('Спортивное ограждение A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sports-fence5.jpg" class="lazyload">
                      <p>Спортивное ограждение A-05</p>
                      <button class="orange-button " @click="openModelWindow('Спортивное ограждение A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/sports-fence6.jpg" class="lazyload">
                      <p>Спортивное ограждение A-06</p>
                      <button class="orange-button " @click="openModelWindow('Спортивное ограждение A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-5-2">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/zabor1.jpg" class="lazyload">
                      <p>Забор из профнастила</p>
                      <button class="orange-button " @click="openModelWindow('Забор из профнастила')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-5-3">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence1.jpg" class="lazyload">
                      <p>Уличное ограждение A-01</p>
                      <button class="orange-button" @click="openModelWindow('Уличное ограждение A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence2.jpg" class="lazyload">
                      <p>Уличное ограждение A-02</p>
                      <button class="orange-button " @click="openModelWindow('Уличное ограждение A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence3.jpg" class="lazyload">
                      <p>Уличное ограждение A-03</p>
                      <button class="orange-button " @click="openModelWindow('Уличное ограждение A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence4.jpg" class="lazyload">
                      <p>Уличное ограждение A-04</p>
                      <button class="orange-button" @click="openModelWindow('Уличное ограждение A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence5.jpg" class="lazyload">
                      <p>Уличное ограждение A-05</p>
                      <button class="orange-button " @click="openModelWindow('Уличное ограждение A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence6.jpg" class="lazyload">
                      <p>Улчиное ограждение A-06</p>
                      <button class="orange-button " @click="openModelWindow('Уличное ограждение A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence7.jpg" class="lazyload">
                      <p>Уличное ограждение A-07</p>
                      <button class="orange-button" @click="openModelWindow('Уличное ограждение A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence8.jpg" class="lazyload">
                      <p>Уличное ограждение A-08</p>
                      <button class="orange-button " @click="openModelWindow('Уличное ограждение A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fence9.jpg" class="lazyload">
                      <p>Уличное ограждение A-09</p>
                      <button class="orange-button " @click="openModelWindow('Уличное ограждение A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
              </div>
                <div class="subcategory-sizetable container" id="c-5-4">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings1.jpg" class="lazyload">
                      <p>Витраж металлический</p>
                      <button class="orange-button" @click="openModelWindow('Витраж металлический')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings2.jpg" class="lazyload">
                      <p>Дверь-решетка A-01</p>
                      <button class="orange-button " @click="openModelWindow('Дверь-решетка A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings3.jpg" class="lazyload">
                      <p>Дверь-решетка A-02</p>
                      <button class="orange-button " @click="openModelWindow('Дверь-решетка A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings4.jpg" class="lazyload">
                      <p>Перегородка металлическая</p>
                      <button class="orange-button" @click="openModelWindow('Перегородка металлическая')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings5.jpg" class="lazyload">
                      <p>Дверь-решетка A-03</p>
                      <button class="orange-button " @click="openModelWindow('Дверь-решетка A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings6.jpg" class="lazyload">
                      <p>Решетка для окна A-01</p>
                      <button class="orange-button " @click="openModelWindow('Решетка для окна A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings7.jpg" class="lazyload">
                      <p>Дверь-решетка A-04</p>
                      <button class="orange-button" @click="openModelWindow('Дверь-решетка A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings8.jpg" class="lazyload">
                      <p>Решетка для окна A-02</p>
                      <button class="orange-button " @click="openModelWindow('Решетка для окна A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/gratings9.jpg" class="lazyload">
                      <p>Дверь-решетка A-05</p>
                      <button class="orange-button " @click="openModelWindow('Дверь-решетка A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-6-1">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fix-gate1.jpg" class="lazyload">
                      <p>Реставрация профессиянальных алюминиевых футбольных ворот</p>
                      <button class="orange-button " @click="openModelWindow('Реставрация профессиянальных алюминиевых футбольных ворот')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fix-gate2.jpg" class="lazyload">
                      <p>Реставрация юниорских алюминиевых футбольных ворот</p>
                      <button class="orange-button " @click="openModelWindow('Реставрация юниорских алюминиевых футбольных ворот')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/fix-gate3.jpg" class="lazyload">
                      <p>Реставрация алюминиевых мини-футбольных ворот</p>
                      <button class="orange-button " @click="openModelWindow('Реставрация алюминиевых мини-футбольных ворот')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-6-2">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/painting.jpg" class="lazyload">
                      <p>Покраска порошковой краской</p>
                      <button class="orange-button " @click="openModelWindow('Покраска порошковой краской')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-6-3">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/turbo.jpg" class="lazyload">
                      <p>Трибуна судейская</p>
                      <button class="orange-button " @click="openModelWindow('Трибуна судейская')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-6-4">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/work1.jpg" class="lazyload">
                      <p>Работы с железом</p>
                      <button class="orange-button " @click="openModelWindow('Работы с железом')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/work2.jpg" class="lazyload">
                      <p>Работы с аллюминием</p>
                      <button class="orange-button " @click="openModelWindow('Работы с аллюминием')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-7-1">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/skot.jpg" class="lazyload">
                      <p>Cтанок КРС</p>
                      <button class="orange-button " @click="openModelWindow('Cтанок КРС')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-8-1">
                 <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os1.jpg" class="lazyload">
                      <p>Освещения A-01</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os2.jpg" class="lazyload">
                      <p>Освещения A-02</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os3.jpg" class="lazyload">
                      <p>Станок для КРС</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os4.jpg" class="lazyload">
                      <p>Освещения A-04</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os5.jpg" class="lazyload">
                      <p>Освещения A-05</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os6.jpg" class="lazyload">
                      <p>Освещения A-06</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os7.jpg" class="lazyload">
                      <p>Освещения A-07</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os8.jpg" class="lazyload">
                      <p>Освещения A-08</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/os9.jpg" class="lazyload">
                      <p>Освещения A-09</p>
                      <button class="orange-button " @click="openModelWindow('Освещения A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-8-2">
                 <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor1.jpg" class="lazyload">
                      <p>Декор A-01</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor2.jpg" class="lazyload">
                      <p>Декор A-02</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor3.jpg" class="lazyload">
                      <p>Декор A-03</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor4.jpg" class="lazyload">
                      <p>Декор A-04</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor5.jpg" class="lazyload">
                      <p>Декор A-05</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor6.jpg" class="lazyload">
                      <p>Декор A-06</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor7.jpg" class="lazyload">
                      <p>Декор A-07</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor8.jpg" class="lazyload">
                      <p>Декор A-08</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/decor9.jpg" class="lazyload">
                      <p>Декор A-09</p>
                      <button class="orange-button " @click="openModelWindow('Декор A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-8-3">
                 <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/bathroom1.jpg" class="lazyload">
                      <p>Вешалка для полотенец</p>
                      <button class="orange-button " @click="openModelWindow('Вешалка для полотенец')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>
               <div class="subcategory-sizetable container" id="c-8-4">
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki1.jpg" class="lazyload">
                      <p>Настенные полки A-01</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-01')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki2.jpg" class="lazyload">
                      <p>Настенные полки A-02</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-02')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki3.jpg" class="lazyload">
                      <p>Настенные полки A-03</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-03')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki4.jpg" class="lazyload">
                      <p>Настенные полки A-04</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-04')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki5.jpg" class="lazyload">
                      <p>Настенные полки A-05</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-05')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki6.jpg" class="lazyload">
                      <p>Настенные полки A-06</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-06')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki7.jpg" class="lazyload">
                      <p>Настенные полки A-07</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-07')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki8.jpg" class="lazyload">
                      <p>Настенные полки A-08</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-08')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki9.jpg" class="lazyload">
                      <p>Настенные полки A-09</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-09')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki10.jpg" class="lazyload">
                      <p>Настенные полки A-010</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-010')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki11.jpg" class="lazyload">
                      <p>Настенные полки A-011</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-011')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                  <div class="col-md-4 block-order">
                    <div class="ficha">
                      <img data-src="img/polki12.jpg" class="lazyload">
                      <p>Настенные полки A-012</p>
                      <button class="orange-button " @click="openModelWindow('Настенные полки A-012')">УЗНАТЬ ЦЕНУ</button>
                    </div>
                  </div>
                </div>
               </div>

       <footer id="footer-order">
            <div class="container">
                <div class="row" id="no-scroll">
                    <div class="col-md-3">
                        <h6>Адрес</h6>
                        <p>
                        г. Алматы<br>
                        ул. Бродского 129а</p>
                        <p>&nbsp;</p>
                    </div>
                    <div class="col-md-3">
                      <h6>Телефон</h6>
                      <p>+7 701 785-80-90</p>
                      <br class="d-none d-md-block"><br>
                    </div>
                    <div class="col-md-3">
                        <h6>Связаться</h6>
                        <a href="mailto:fish74@mail.ru"><i class="fas fa-envelope"></i>fish74@mail.ru</a><br>
                        <a href="https://wa.me/77017858090"><i class="fab fa-whatsapp"></i>Whatsapp</a><br>
                        <p>&nbsp;</p>                       
                    </div>
                    <div class="col-md-3">
                        <h6>Соцсети</h6>
                        <a href="https://www.instagram.com/ironbrokz/" target="blank">
                            <img data-src="img/inst.svg" class="lazyload social-network-img">
                        </a>
                        <a href="https://www.facebook.com/ironbrokz" target="blank">
                            <img data-src="img/fb.svg" class="lazyload social-network-img">
                        </a><br>
                        <p>&nbsp;</p>
                    </div>                
                  </div>
            </div>
        </footer> 
     
  
  <div id="modal-shadow"></div>
  <div id="modal-window-form">
            <i class="fas fa-times close-button" id="close-button" @click="close"></i>
            <h4 id="form-head" >
                Заявка
            </h4>
            <p id="id-form" ></p>
            <form method="POST" class="contact-form" action="mail2/handler.php">
                <div class="input-icon"><i class="fas fa-user"></i></div>
                <input type="text" name="name" id="name-mwform" placeholder="Ваше имя *" required>
                <div class="input-icon"><i class="fas fa-envelope"></i></div>
                <input type="email" name="email" id="email-mwform" placeholder="Email" required>
                <div class="input-icon"><i class="fas fa-phone" style="transform: rotateZ(90deg);"></i></div>
                <input type="tel" name="phone" id="phone-mwform" placeholder="Телефон *" required>
                <textarea rows="1" name="question" id="question-mwform" placeholder="Коментарий к заказу"></textarea>
                <input type="hidden" name="hid" id="hid-mwform" value="">
                <button type="submit" class="orange-button" id="button-application">ОТПРАВИТЬ ЗАЯВКУ</button>
                <div class="form-messages" id="form-messages-mwform"></div>
            </form>
            <div class="success_message" style="display:none">
                <p> Заявка успешно отправлена! </p>
            </div>
            <div class="error_message" style="width:100%; height:100%; display:none; "> 
                <p>Произошла ошибка при отправке. Попробуйте еще раз.</p> 
            </div> 
        </div>

</div>
</div>
</div>
</div>
</template>
<script type="text/javascript">
    export default {
       name: "order",
       methods: {
        openModelWindow (title){
          document.getElementById('modal-shadow').style.cssText = 'display: block';
          document.getElementById('modal-window-form').style.cssText = 'display: block';
          document.getElementById('id-form').innerHTML = title ;
          document.getElementById('hid-mwform').value = title;
        }
      },
       mounted: function(){
          if(this.$route.query.fromHome == true) {
             $('html, body').animate({
               scrollTop: $("#order").offset().top
           }, 1);
         };
         $('#order-header').text(this.$route.query.itemTitle);
         $('#order-image-m').attr('src','img/' + this.$route.query.itemId + '.jpg');
         $('#order-image-d').attr('src','img/' + this.$route.query.itemId + '.jpg');
         $('.subcategory-sizetable').css('display','none');
         $('#' + this.$route.query.itemId).css('display','block');
         $('.router-button').css('display','none');
         $('#button' + this.$route.query.tab).css('display','block');
         $('#hid-order').val('Новая заявка - ' + this.$route.query.itemTitle);


                $(function(){ 
                function after_form_submitted(data) 
                {
                    if(data.result == 'success')
                    {
                        //$('form#reused_form').hide();
                        $('.success_message').show();
                        $('.error_message').hide();
                        $('#name-order').val('');
                        $('#phone-order').val('');
                        $('#email-order').val('');
                    }
                    else
                    {
                        $('.error_message').append('<ul></ul>');

                        jQuery.each(data.errors,function(key,val)
                        {
                            $('.error_message ul').append('<li>'+key+':'+val+'</li>');
                        });
                        $('.success_message').hide();
                        $('.error_message').show();

                        //reverse the response on the button
                        /*$('button[type="button"]', $form).each(function()
                        {
                            $btn = $(this);
                            label = $btn.prop('orig_label');
                            if(label)
                            {
                                $btn.prop('type','submit' ); 
                                $btn.text(label);
                                $btn.prop('orig_label','');
                            }
                        });*/
                        
                    }//else
                }

                $('.contact-form').submit(function(e)
                  {
                    e.preventDefault();

                    var form = $(this);
                    //show some response on the button
                

                                $.ajax({
                            type: "POST",
                            url: 'mail2/handler.php',
                            data: $(form).serialize(),
                            success: after_form_submitted,
                            dataType: 'json' 
                        });        
                    
                  });
                }); 
   
    document.getElementById('close-button').onclick = function(){
      close();
    };
     document.getElementById('modal-shadow').onclick = function(){
      close();
    };
    function close() {
      document.getElementById('modal-shadow').style.cssText='display:none';
      document.getElementById('modal-window-form').style.cssText='display:none';
    }

    
    
     }
 }

</script>
