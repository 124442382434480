<template>
  <div>
        <nav class="navbar">
            <div class="container">
                <img src="img/logo.svg" class="navbar__logo navbar__logo_pad">
                <a class="menu-link menu-link_active desktop" href="javascript:;" @click="menuClick('')">Главная</a>
                <a class="menu-link menu-link_disabled desktop" href="javascript:;" @click="menuClick('#catalog')">Продукция</a>
                <a class="menu-link menu-link_disabled desktop" href="javascript:;" @click="menuClick('#portfolio')">Портфолио</a>
                <a class="menu-link menu-link_disabled desktop" href="javascript:;" @click="menuClick('#reasons')">Преимущества</a>
                <a class="menu-link menu-link_disabled desktop" href="javascript:;" @click="menuClick('#partners')">Клиенты</a>
                <a class="menu-link menu-link_disabled desktop" href="javascript:;" @click="menuClick('#faq')">Вопросы</a>
                <a class="menu-link menu-link_disabled desktop" href="javascript:;" @click="menuClick('#application')">Заявка</a>
                <a href="tel:+77017858090" class="navbar__phone-href navbar__phone_href_pad d-none d-lg-block">
                    <i class="fas fa-phone"></i>
                    +7 701 785 8090
                </a>
                <a href="tel:+77017858090" class="navbar__phone-href navbar__phone_href_pad phone-home d-block d-lg-none">
                    <i class="fas fa-phone">
   
                    </i>
                </a>
                <img src="img/menu.svg" class="d-block d-xl-none" id="open-menu" value="Click" onmousedown="viewMenu()">
            </div>
        </nav>
        <div id="intro">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 col-xl-6">
                        <h1>Малые архитектурные формы, спортивное оборудование и металлоизделия</h1>
                        <p>
                            Полный цикл производства малых архитектурных форм, спортивного оборудование, металлоизделия с широким 
                            <br class="d-block d-sm-none d-lg-block">спектром дополнительных услуг. 
                            <!--<br class="d-block d-sm-none d-lg-block">Реальное  качество-в реальные сроки-за реальные деньги --> 
                        </p>
                        <div class="custom-flex-row">
                            <button class="orange-button orange-button-mobile formbut-mw" id="button-intro" @click="setFormDescr('Получите бесплатную консультацию о наших услугах')">ОТПРАВИТЬ ЗАЯВКУ</button>
                        </div>
                    </div>
                    <div class="col-md-5 col-xl-6">
                        <div class="intro-slider">
                                <!--<div>
                                    <img src="img/intro1.png">
                                </div>
                                -->
                                <div>
                                    <img data-src="img/intro2.png" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/intro3.png" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/intro4.png" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/intro5.png" class="lazyload">
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="numbers" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 custom-flex-row">
                        <span class="numbers__big-number">15</span>
                        <span>Лет безупречной <br>работы на рынке</span>
                    </div>
                    <div class="col-md-4 custom-flex-row">
                        <span class="numbers__big-number">40+</span>
                        <span>Видов разной <br>продукции</span>
                    </div>
                    <div class="col-md-4 custom-flex-row">
                        <span class="numbers__big-number">500+</span>
                        <span>Довольных <br>клиентов</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="lead-magnet-1" class="lead-magnet">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 lead-magnet-left">
                        <h2>Бесплатная доставка</h2>
                        <p>Ценим время своих клиентов и доставляем готовую продукцию в необходимое место и время. <br class="desktop">Не тратьте личные ресурсы для самовывоза заказа, мы выполним доставку сами. </p>
                    </div>
                    <div class="col-md-3 lead-magnet-right">
                        <button class="white-button formbut-mw" id="button-lm1" @click="setFormDescr('Узнайте больше о доставке продукции')">ЗАКАЗАТЬ</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="catalog" class="section">
            <div class="container">
                <h2>Наша продукция</h2>
                <p>Мы производим широкий спектр изделий из различных материалов и в различном <br class="desktop">
                    исполнении, удовлетворяющий потребности клиентов при их использовании.</p>
                <div class="d-none d-sm-block">
                    <div class="row col-sm-12">
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-3.svg" class="lazyload">
                        <h6>Малые архитектурные <br>формы</h6>
                        <button id="c-3" data-tab="c-3" data-header="Малые архитектурные формы" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-1.svg" class="lazyload">
                        <h6>Спортивный <br>инвентарь</h6>
                        <button id="c-1" data-tab="c-1" data-header="Спортивный инвентарь" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-5.svg" class="lazyload">
                        <h6>Заборы <br>и ограждения</h6>
                        <button id="c-5" data-tab="c-5" data-header="Заборы и ограждения" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-4.svg" class="lazyload">
                        <h6>Трибуны<br>зрительские</h6>
                        <button id="c-4" data-tab="c-4" data-header="Трибуны зрительские" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    </div>
                    <div class="row col-sm-12">
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-6.svg" class="lazyload">
                        <h6>Различные <br>услуги</h6>
                        <button id="c-6" data-tab="c-6" data-header="Различные услуги" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-7.svg" class="lazyload">
                        <h6>Оборудование <br>на заказ</h6>
                        <button id="c-7" data-tab="c-7" data-header="Оборудование на заказ" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-2.svg" class="lazyload">
                        <h6>Мебель <br>на заказ</h6>
                        <button id="c-2" data-tab="c-2" data-header="Мебель" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    <div class="col-6 col-lg-3 catalog-base-item">
                        <img data-src="img/c-8.svg" class="lazyload">
                        <h6>Элементы <br>интерьера</h6>
                        <button id="c-8" data-tab="c-8" data-header="Элементы интерьера" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                    </div>
                    </div>
                </div>
                <div class="d-block d-sm-none">
                    <div class="row">
                         <div class="col-6 catalog-base-item">
                            <img data-src="img/c-3.svg" class="lazyload">
                            <h6>Малые архитектурные <br>формы</h6>
                            <button id="c-3" data-tab="c-3" data-header="Малые архитектурные формы" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                        <div class="col-6 catalog-base-item">
                            <img data-src="img/c-1.svg" class="lazyload">
                            <h6>Спортивный <br>инвентарь</h6>
                            <button id="c-1" data-tab="c-1" data-header="Спортивный инвентарь" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 catalog-base-item">
                            <img data-src="img/c-5.svg" class="lazyload">
                            <h6>Заборы <br>и ограждения</h6>
                            <button id="c-5" data-tab="c-5" data-header="Заборы и ограждения" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                        <div class="col-6 catalog-base-item">
                            <img data-src="img/c-4.svg" class="lazyload">
                            <h6>Трибуны<br>зрительские</h6>
                            <button id="c-4" data-tab="c-4" data-header="Трибуны зрительские" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 catalog-base-item">
                            <img data-src="img/c-6.svg" class="lazyload">
                            <h6>Различные <br>услуги</h6>
                            <button id="c-6" data-tab="c-6" data-header="Различные услуги" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                        <div class="col-6 catalog-base-item">
                            <img data-src="img/c-7.svg" class="lazyload">
                            <h6>Оборудование <br>на заказ</h6>
                            <button id="c-7" data-tab="c-7" data-header="Оборудование на заказ" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 catalog-base-item">
                            <img data-src="img/c-2.svg" class="lazyload">
                            <h6>Мебель <br>на заказ</h6>
                            <button id="c-2" data-tab="c-2" data-header="Мебель" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                        <div class="col-6 catalog-base-item">
                            <img data-src="img/c-8.svg" class="lazyload">
                            <h6>Элементы <br>интерьера</h6>
                            <button id="c-8" data-tab="c-8" data-header="Элементы интерьера" class="c-button c-button-1 orange-button">ПОДРОБНЕЕ</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div id="portfolio" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-12 lead-magnet-left">
                        <h2>Портфолио продукции</h2>
                        <p>При производстве мы уделяем большое внимание желаниям клиента и помогаем  
                            <br> в поиске альтернативных решений</p>
                    </div>
                    <div class="col-sm-4 portfolio-menu">
                        <p class="portfolio-menu__item portfolio-menu__item_active" id="portfolio-tab-1-button">Урны</p>
                        <p class="portfolio-menu__item portfolio-menu__item_disabled" id="portfolio-tab-2-button">Вазоны</p>
                        <p class="portfolio-menu__item portfolio-menu__item_disabled" id="portfolio-tab-3-button">Трибуны</p>
                        <p class="portfolio-menu__item portfolio-menu__item_disabled" id="portfolio-tab-4-button">Воркаут</p>
                        <p class="portfolio-menu__item portfolio-menu__item_disabled" id="portfolio-tab-5-button">Мебель</p>
                        <p class="portfolio-menu__item portfolio-menu__item_disabled" id="portfolio-tab-6-button">Навес</p>
                        <p class="portfolio-menu__item portfolio-menu__item_disabled" id="portfolio-tab-7-button">Скамейки</p>
                        <p class="portfolio-menu__item portfolio-menu__item_disabled" id="portfolio-tab-8-button">Щиты баскетбольные</p>
                    </div>
                    <div class="col-sm-7 portfolio-sliders">
                        <img src="img/arrow.svg" class="desktop" id="portfolio-slider-prev">
                        <img src="img/arrow.svg" class="desktop" id="portfolio-slider-next">
                        <div class="portfolio-tab" id="portfolio-tab-1">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/urni1.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/urni2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/urni3.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/urni4.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                        <div class="portfolio-tab" id="portfolio-tab-2" style="display: none;">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/vazon1.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/vazon2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/vazon3.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/vazon4.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/vazon5.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                        <div class="portfolio-tab" id="portfolio-tab-3" style="display: none;">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/tribune1.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/tribune2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/tribune4.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/tribune5.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                        <div class="portfolio-tab" id="portfolio-tab-4" style="display: none;">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/workout1.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/workout2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/workout3.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/workout4.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/workout5.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                        <div class="portfolio-tab" id="portfolio-tab-5" style="display: none;">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/mebel1.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/mebel2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/mebel3.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/mebel4.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/mebel5.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                        <div class="portfolio-tab" id="portfolio-tab-6" style="display: none;">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/naves2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/naves3.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/naves4.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/naves5.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                        <div class="portfolio-tab" id="portfolio-tab-7" style="display: none;">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/ska1.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/ska2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/ska3.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/ska4.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                        <div class="portfolio-tab" id="portfolio-tab-8" style="display: none;">
                            <div class="portfolio-slider">
                                <div>
                                    <img data-src="img/basket1.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/basket2.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/basket3.jpg" class="lazyload">
                                </div>
                                <div>
                                    <img data-src="img/basket4.jpg" class="lazyload">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="lead-magnet-2" class="lead-magnet">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 lead-magnet-left">
                        <h2>Проектирование с нуля</h2>
                        <p>У наших специалистов есть необходимые знания и большой практический опыт, поэтому <br class="desktop">мы гарантируем высокое качество предоставляемых услуг от разработки проекта по индивидуальному заказу до установки готовых изделий.</p>
                    </div>
                    <div class="col-md-3 lead-magnet-right">
                        <button class="white-button formbut-mw" id="button-lm2" @click="setFormDescr('Получите информацию по выбору продукции')">ЗАКАЗАТЬ</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="reasons" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 reasons-grid">
                        <h2>Почему клиенты выбирают нас?</h2>
                        <p>
                            Более девяти лет специалисты нашей компании производят<br class="desktop">
                                самую различную металлопродукцию высокого качества  
                        </p>
                        <div class="row">
                            <div class="col-sm-4 desktop">
                                <img src="img/why1.svg">
                                <h5>15 лет успешной <br>работы
                                </h5>
                                <p>
                                    Компания имеет большой опыт<br> 
                                    в производстве спортинвентаря, <br>мебели для спортивных<br>учреждений и малых <br>архитектурных форм.
                                </p>
                            </div>
                            <div class="col-sm-4 desktop">
                                <img src="img/why2.svg">
                                <h5>Квалифицированные <br>специалисты 
                                </h5>
                                <p>
                                    Благодаря профессионалам мы <br>просчитываем выгодные и быстрые<br> условия производства. Наши <br>специалисты работают максимально <br>быстро и комфортно для клиентов.
                                </p>
                            </div>
                             <div class="col-sm-4 desktop">
                                <img src="img/why3.svg">
                                <h5>Строгий контроль<br> 
                                    качества продукта  
                                </h5>
                                <p>
                                    Мы следим за качеством <br>продукции и услуг. Наша<br> 
                                    работа основана на принципах<br> долгосрочного сотрудничества,<br> взаимопонимания и доверия.
                                </p>
                            </div>
                        </div>
                        <div class="row" id="align">
                            <div class="col-sm-4 desktop">
                                <img src="img/why4.svg">
                                <h5>Своевременно выполняем
                                        <br>в указанные сроки
                                </h5>
                                <p>
                                    Завершение работы <br>   
                                    по четко поставленному плану,<br> 
                                    в сроки, что является нашей <br>визитной карточкой.
                                </p>
                            </div>
                            <div class="col-sm-4 desktop">
                                <img src="img/why5.svg">
                                <h5>Приемлемые <br>цены
                                </h5>
                                <p>Компания предлагает высокое <br>качество по самым доступным <br>ценам. Оказываем полный цикл<br>
                                в производстве, от заготовки <br>материалов до окраски изделий.
                                </p>
                            </div>
                             <div class="col-sm-4 desktop">
                                <img src="img/why6.svg">
                                <h5>Доставка по <br>всей стране 
                                </h5>
                                <p>
                                    Мы осуществляем доставку<br> 
                                    с последующей сборкой<br> 
                                    продукции по всему Казахстану,  <br>доставка проходит быстро и в сроки. 
                                </p>
                            </div>
                        </div>
                        
                            <div class="mobile relative">
                                <img src="img/arrow.svg" id="advs-slider-prev">
                                <img src="img/arrow.svg" id="advs-slider-next">
                                <div class="col-12 advs-slider">
                                    <div>
                                        <img data-src="img/why1.svg" class="lazyload">
                                        <h5>15 лет успешной <br>работы
                                        </h5>
                                        <p>
                                            Компания имеет большой опыт<br> 
                                            в производстве спортинвентаря, <br>мебели для спортивных<br>учреждений и малых <br>архитектурных форм.
                                        </p>
                                    </div>
                                    <div>
                                        <img data-src="img/why2.svg" class="lazyload">
                                        <h5>Квалифицированные <br>специалисты 
                                        </h5>
                                        <p>
                                            Благодаря профессионалам мы <br>просчитываем выгодные и быстрые<br> условия производства.Наши <br>специалисты работают максимально <br>быстро и комфортно для клиентов
                                        </p>
                                    </div>
                                    <div>
                                        <img data-src="img/why3.svg" class="lazyload">
                                        <h5>Строгий контроль<br> 
                                            качества продукта  
                                        </h5>
                                        <p>
                                            Мы следим за качеством <br>продукции и услуг. Наша<br> 
                                            работа основана на принципах<br> долгосрочного сотрудничества,<br> взаимопонимания и доверия.
                                        </p>
                                    </div>
                                    <div>
                                        <img data-src="img/why4.svg" class="lazyload">
                                        <h5>Своевременно выполняем
                                        <br>в указанные сроки
                                        </h5>
                                        <p>
                                            Завершение работы <br>   
                                            по четко поставленному плану,<br> 
                                            в сроки, что является нашей <br>визитной карточкой.
                                        </p>
                                    </div>
                                    <div>
                                        <img data-src="img/why5.svg" class="lazyload">
                                        <h5>Приемлемые <br>цены
                                        </h5>
                                            <p> Компания предлагает высокое <br>качество по самым   доступным <br>ценам.Оказываем полный цикл<br>
                                                в производстве, от заготовки <br>материалов до окраски изделий.
                                            </p>
                                    </div>
                                    <div>
                                        <img data-src="img/why6.svg" class="lazyload">
                                         <h5>Доставка по <br>всей стране 
                                        </h5>
                                        <p>
                                            Мы осуществляем доставку<br> 
                                            с последуюещей сборкой<br> 
                                            продукции по всему Казахстану,  <br>доставка проходит быстро и в сроки. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="partners">
            <div class="container">
                <h2>Наши клиенты</h2>
                <p>За годы работы мы оказали помощь многим частным лицам и компаниям.<br> 
                    Мы ценим ваше доверие!</p>
                <div class="partners-grid-block row">
                    <div class="partners-grid desktop-flex">
                        <div class="partners-grid-item"><img data-src="img/partner (1).svg" class="lazyload"></div>
                        <div class="partners-grid-item"><img data-src="img/partner (2).svg" class="lazyload"></div>
                        <div class="partners-grid-item"><img data-src="img/partner (3).svg" class="lazyload"></div>
                        <div class="partners-grid-item"><img data-src="img/partner (4).svg" class="lazyload"></div>
                        <div class="partners-grid-item"><img data-src="img/partner (5).svg" class="lazyload"></div>
                        <div class="partners-grid-item"><img data-src="img/partner (6).svg" class="lazyload"></div>
                        <div class="partners-grid-item"><img data-src="img/partner (7).svg" class="lazyload"></div>
                        <div class="partners-grid-item"><img data-src="img/partner (8).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (9).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (10).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (11).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (12).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (13).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (14).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (15).svg" class="lazyload"></div>
                        <div class="partners-grid-item partners-second-part"><img data-src="img/partner (16).svg" class="lazyload"></div>
                    </div>
                </div>
                <div class="mobile">
                        <div class="portfolio-slider">
                            <div>
                                <div class="partners-grid-item"><img data-src="img/partner (1).svg" class="lazyload"></div>
                                <div class="partners-grid-item"><img data-src="img/partner (2).svg" class="lazyload"></div>
                                <div class="partners-grid-item"><img data-src="img/partner (3).svg" class="lazyload"></div>
                                <div class="partners-grid-item"><img data-src="img/partner (4).svg" class="lazyload"></div>
                            </div>
                            <div>
                                <div class="partners-grid-item"><img data-src="img/partner (5).svg" class="lazyload"></div>
                                <div class="partners-grid-item"><img data-src="img/partner (6).svg" class="lazyload"></div>
                                <div class="partners-grid-item"><img data-src="img/partner (7).svg" class="lazyload"></div>
                                <div class="partners-grid-item"><img data-src="img/partner (8).svg" class="lazyload"></div>
                            </div>
                            <div>
                                <div class="partners-grid-item"><img data-src="img/partner (9).svg" class="lazyload"></div>
                                <div class="partners-grid-item"><img data-src="img/partner (10).svg" class="lazyload"></div>
                                <div class="partners-grid-item partners-line-mobile"><img data-src="img/partner (11).svg" class="lazyload"></div>
                                <div class="partners-grid-item partners-line-mobile"><img data-src="img/partner (12).svg" class="lazyload"></div>
                            </div>
                            <div>
                                <div class="partners-grid-item partners-line partners-line-mobile"><img data-src="img/partner (13).svg" class="lazyload"></div>
                                <div class="partners-grid-item partners-line"><img data-src="img/partner (14).svg" class="lazyload"></div>
                                <div class="partners-grid-item partners-line partners-line-mobile"><img data-src="img/partner (15).svg" class="lazyload"></div>
                                <div class="partners-grid-item partners-line partners-line-mobile"><img data-src="img/partner (16).svg" class="lazyload"></div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <!--
        <div id="reviews">
            <div class="container">
                <h2>Благодарности довольных клиентов</h2>
                <p>Мы дорожим клиентами и делаем все для того, чтобы работать с нами <br class="desktop">
                    было комфортно и Ваш бизнес процветал с нашей помощью
                </p>
                <div class="row justify-content-center">
                    <img src="img/arrow.svg" id="reviews-slider-prev">
                    <img src="img/arrow.svg" id="reviews-slider-next">
                    <div class="col-10 reviews-slider">
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="col-sm-4 reviews-slider-left">
                                    <a href="img/letter1.jpg" data-lightbox="letter1">
                                        <img src="img/letter1.jpg">
                                    </a>
                                </div>
                                <div class="col-sm-8 reviews-slider-right">
                                    <p>
                                        <b>Тушина Ю.В.</b><br>
                                        Директор ТОО "G-FORCE"
                                    </p>
                                    <p>
                                        TOO "ПК Муравей" является нашим подрядчиком в области производства и печати различных буклетов, лифлетов, каталогов, открыток, стикеров на протяжение 3-х лет.<br><br>
                                        За это время ТОО "ПК Муравей" показал свою способность с полной ответственностью подходить к поставленным задачам, выполнять работы качественно, в срок и по хорошим ценам Отличительной чертой работы ТОО "ПК Муравей" является высокий профессионализм организованность сотрудников компании в кратчайшие сроки обрабатывать заказы и качество печати.<br><br>
                                        Рекомендуем ТОО "ПК Муравей" как надежного и стабильного партнера 

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="col-sm-4 reviews-slider-left">
                                    <a href="img/letter2.jpg" data-lightbox="letter2">
                                        <img src="img/letter2.jpg">
                                    </a>
                                </div>
                                <div class="col-sm-8 reviews-slider-right">
                                    <p>
                                        <b>Абдикаримов Н.Н.</b><br>
                                        Генеральный директор ТОО "ASADEL DIY ALMATY-1"
                                    </p>
                                    <p>
                                        Выражаем благодарность типографии «ПК Муравей» за высокое качество выполнения работ по печати. С удовлетворением отмечаем четкое исполнение договорных обязательств, использование современного печатного оборудования и высокий профессионализм сотрудников.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="col-sm-4 reviews-slider-left">
                                    <a href="img/letter3.jpg" data-lightbox="letter3">
                                        <img src="img/letter3.jpg">
                                    </a>
                                </div>
                                <div class="col-sm-8 reviews-slider-right">
                                    <p>
                                        <b>Пузан И.И.</b><br>
                                        Начальник отдела розничного маркетинга и продуктов АО «Евразийский банк»
                                    </p>
                                    <p>
                                        АО «Евразийский банк» подтверждает, что ТОО "ПК Муравей" является нашим подрядчиком в области производства и печати различной полиграфической рекламной продукции на протяжение 9 лет.<br><br>
                                        В течении этого времени ТОО «ПК Муравей» отличался от других поставщиков своим профессиональным подходом к выполнению поставленных задач, также выполняли трудновыполнимые и ограниченные по срокам заказы по ценам ниже рыночной.<br><br>
                                        Рекомендуем ТОО "ПК Муравей" как надежного и стабильного подрядчика.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="col-sm-4 reviews-slider-left">
                                    <a href="img/letter4.jpg" data-lightbox="letter4">
                                        <img src="img/letter4.jpg">
                                    </a>
                                </div>
                                <div class="col-sm-8 reviews-slider-right">
                                    <p>
                                        <b>Шатилова Т.Н.</b><br>
                                        Первый вице-президент ТОО «Алматыкітап баспасы»
                                    </p>
                                    <p>
                                        Типография «Муравей» является партнером ТОО «Алматыкітап баспасы» более 5 лет.<br><br>
                                        За эти годы в данной типографии мы напечатали более 3 миллионов экземпляров учебной литературы. Качество полиграфической продукции типографии «Муравей» отвечает всем требованиям и нормативам. Имеется сертификат CTKZ.<br><br>
                                        Типография «Муравей» очень внимательно и ответственно относится к заказчику, стоит отметить и профессионализм сотрудников типографии.<br><br>
                                        Все заказы типография «Муравей» исполняет в срок.


                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    -->
        <div id="faq" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 col-xl-9 lead-magnet-left">
                        <h2>Часто задаваемые вопросы</h2>
                        <p>Если у вас остались кое какие вопросы к нам вы можете нам задать</p>
                    </div>
                    <div class="col-md-5 col-xl-3 lead-magnet-right">
                        <button class="orange-button orange-button-mobile formbut-mw" id="button-faq" @click="setFormDescrQ('Мы оперативно ответим на Ваши обращения', 'Задать вопрос')">ЗАДАТЬ СВОЙ ВОПРОС</button>
                    </div>
                </div>
                <div class="col-sm-12 faq-item-wrapper" id="q1">
                    <p>
                        1. Ваши специалисты выезжают на замер ?
                        <img src="img/faqarrow.svg" id="arr1" class="faq-arrow">
                     </p>
                    <div class="faq-item-answer" id="a1">
                        <p>
                             Да, мы выезжаем на замер в удобное для клиента время. Считаем этот этап одним из наиболее важных и очень ответственно подходим к делу. В рамках проведения замерочных работ проводятся все необходимые мероприятия.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 faq-item-wrapper" id="q2">
                    <p>
                        2. Какие способы оплаты вы принимаете ?
                        <img src="img/faqarrow.svg" id="arr2" class="faq-arrow">
                    </p>
                    <div class="faq-item-answer" id="a2">
                            <p>
                            Можно оплатить любым удобным для вас способом.  Для оплаты наличным способом необходимо согласовать с директором сроки поставки и получить счет-договор. Физическим лицам доступна возможность оплаты заказа по счету через мобильное приложение банка.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 faq-item-wrapper" id="q3">
                    <p>
                        3. Какие материалы закупаются ?
                        <img src="img/faqarrow.svg" id="arr3" class="faq-arrow">
                    </p>
                    <div class="faq-item-answer" id="a3">
                        <p>
                            Для производства вашего заказа мы подбираем высококачественные и лучшие материалы. Будем рады сотрудничеству с вами и вашей компанией, также по желанию можем выполнить заказ из ваших материалов.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 faq-item-wrapper" id="q4">
                    <p>
                        4. Сколько времени займет сборка продукции?
                        <img src="img/faqarrow.svg" id="arr4" class="faq-arrow">
                    </p>
                    <div class="faq-item-answer" id="a4">
                        <p>
                            Наша продукция бывает разных форм, конструкции и комплектации. Сборка и монтаж изделий занимает разные объемы по времени. Все это обговаривается  непостредственно по телефону или вы можете задать свой вопрос отправив заявку на нашу почту. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div id="application" class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-10">
                        <h2>
                            Интересует стоимость, сроки изготовления  
                            <br class="desktop">или есть другие вопросы по производству?
                        </h2>
                        <p>
                            Оставьте заявку, мы перезвоним 
                            <br>и проконсультируем по всем вопросам.
                        </p>
                        <div class="row">
                            <div class="col-sm-6">
                                <span><p>Будем рады сотрудничеству</p></span><!-- id="application-form"   -->
                                <form method="POST" class="contact-form" action="mail2/handler.php">
                                    <div class="input-icon"><i class="fas fa-user"></i></div>
                                    <input type="text" name="name" id="name-appform" placeholder="Ваше имя *" required>
                                    <div class="input-icon"><i class="fas fa-envelope"></i></div>
                                    <input type="email" name="email" id="email-appform" placeholder="Email" required>
                                    <div class="input-icon"><i class="fas fa-phone" style="transform: rotateZ(90deg);"></i></div>
                                    <input type="tel" name="phone" id="phone-appform" placeholder="Телефон *" required>
                                    <textarea name="question" id="question-appform" placeholder="Чем мы можем Вам помочь?"></textarea>
                                    <input type="hidden" name="hid" id="hid-appform" value="Форма внизу сайта">
                                    <button type="submit" class="white-button">ОТПРАВИТЬ ЗАЯВКУ</button>
                                </form>
                                <div class="success_message" style="display:none">
                                    <p> Заявка успешно отправлена! </p>
                                </div>
                                <div class="error_message" style="width:100%; height:100%; display:none; "> 
                                    <p>Произошла ошибка при отправке. Попробуйте еще раз.</p> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div class="container">
                <div class="row" id="no-scroll">
                    <div class="col-md-3">
                        <img src="img/logo.svg" class="footer__logo">
                    </div>
                    <div class="col-md-3">
                        <h6>Телефоны</h6>
                        <a href="tel:+77017858090">+7 701 785-80-90</a>
                        <br class="d-none d-md-block"><br>
                        <p>&nbsp;</p>                       
                    </div>
                    <div class="col-md-3">
                        <h6>Связаться</h6>
                        <a href="mailto:fish74@mail.ru"><i class="fas fa-envelope"></i>fish74@mail.ru</a><br>
                        <a href="https://wa.me/77017858090"><i class="fab fa-whatsapp"></i>Whatsapp</a><br>
                        <p>&nbsp;</p>                       
                    </div>
                    <div class="col-md-3">
                        <h6>Соцсети</h6>
                        <a href="https://www.instagram.com/almatasvarkametall2010/" target="blank">
                            <img src="img/inst.svg" class="social-network-img">
                        </a>
                        <a href="https://www.facebook.com/ironbrokz" target="blank">
                            <img src="img/fb.svg" class="social-network-img">
                        </a><br>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </footer>
       
        <div id="menu_mobile">
            <img src="img/logo-menu.svg" id="logo-menu">
            <div class="menu-phone">
                <a onmousedown="closeMenu()" @click="menuClick('')">Главная</a>
                <a onmousedown="closeMenu()" @click="menuClick('#catalog')">Продукция</a>
                <a onmousedown="closeMenu()" @click="menuClick('#portfolio')">Портфолио</a>
                <a onmousedown="closeMenu()" @click="menuClick('#reasons')">Преимущества</a>
                <a onmousedown="closeMenu()" @click="menuClick('#partners')">Клиенты</a>
                <a onmousedown="closeMenu()" @click="menuClick('#faq')">Вопросы</a>
                <a onmousedown="closeMenu()" @click="menuClick('#application')">Заявка</a>
            </div>
            <div class="contact-menu">
                <a href="https://www.instagram.com/almatasvarkametall2010/">
                    <img src="img/inst1.svg">
                </a>
                <a href="https://www.facebook.com/ironbrokz">
                    <img src="img/fb1.svg" id="fb">
                </a>
                <p>fish74@mail.ru</p>
                <p>+7 701 785-80-90</p>
            </div>
            <img src="img/close.svg" id="close-menu" value="Click" onmousedown="closeMenu()">
        </div>
        <div class="modal-shadow"></div>
        <div class="modal-window-form">
            <i class="fas fa-times close-button"></i>
            <h4 id="form-head">
                Оставьте заявку
            </h4>
            <p id="form-descr">
                
                Получите бесплатную консультацию 
                <br class="desktop">о наших услугах
            </p><!-- id="mw-form"   -->
            <form method="POST" class="contact-form" action="mail2/handler.php">
                <div class="input-icon"><i class="fas fa-user"></i></div>
                <input type="text" name="name" id="name-mwform" placeholder="Ваше имя *" required>
                <div class="input-icon"><i class="fas fa-envelope"></i></div>
                <input type="email" name="email" id="email-mwform" placeholder="Email" required>
                <div class="input-icon"><i class="fas fa-phone" style="transform: rotateZ(90deg);"></i></div>
                <input type="tel" name="phone" id="phone-mwform" placeholder="Телефон *" required>
                <textarea rows="1" name="question" id="question-mwform" placeholder="Чем мы можем Вам помочь?"></textarea>
                <input type="hidden" name="hid" id="hid-mwform" value="">
                <button type="submit" class="orange-button" id="button-application">ОТПРАВИТЬ ЗАЯВКУ</button>
                <div class="form-messages" id="form-messages-mwform"></div>
            </form>
            <div class="success_message" style="display:none">
                                    <p> Заявка успешно отправлена! </p>
                                </div>
                                <div class="error_message" style="width:100%; height:100%; display:none; "> 
                                    <p>Произошла ошибка при отправке. Попробуйте еще раз.</p> 
                                </div> 
        </div>
        <div class="modal-window-products" id="">
            <i class="fas fa-times close-button"></i>
            <div class="container">
                <h3 id="modal-window-products-header"></h3>
                                    <div data-tab="c-1" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-1-1.svg">
                                            <h6>Щиты баскетбольные</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Щиты баскетбольные', tab: '1', itemId: 'c-1-1' }}">
                                                <button data-item="c-1-1" data-header="Визитки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>

                                            </router-link>
                                        </div>
                                        
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-1-4.svg">
                                            <h6>Воркаут оборудование</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Воркаут оборудование', tab: '1', itemId: 'c-1-4' } }">    
                                                <button data-item="c-1-4" data-header="Блокноты" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-1-5.svg">
                                            <h6>Ворота <br class="d-block d-sm-none">футбольные</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Ворота футбольные', tab: '1', itemId: 'c-1-5' } }">    
                                                <button data-item="c-1-5" data-header="Папки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-1-6.svg">
                                            <h6>Теннисные <br class="d-block d-sm-none">столы</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Теннисные столы', tab: '1', itemId: 'c-1-6' } }">    
                                                <button data-item="c-1-6" data-header="Папки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-1-3.svg">
                                            <h6>Прочий спортинвентарь</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Прочий спортинвентарь', tab: '1', itemId: 'c-1-3' } }">    
                                                <button data-item="c-1-3" data-header="Конверты" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                    </div>
                                    <div data-tab="c-2" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-1.svg">
                                            <h6>Офисная <br>мебель</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Офисная  мебель', tab: '2', itemId: 'c-2-1' } }">    
                                                <button data-item="c-2-1" data-header="Листовки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-2.svg">
                                            <h6>Мебель <br>для спортзала</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Мебель для спортзала', tab: '2', itemId: 'c-2-2' } }">    
                                                <button data-item="c-2-2" data-header="Листовки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-3.svg">
                                            <h6>Мебель <br>для сада</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Мебель для сада', tab: '2', itemId: 'c-2-3' } }">    
                                                <button data-item="c-2-3" data-header="Листовки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                         <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-4.svg">
                                            <h6>Мебель <br>в стиле лофт</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Мебель в стиле LOFT', tab: '2', itemId: 'c-2-4' } }">    
                                                <button data-item="c-2-4" data-header="Листовки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-5.svg">
                                            <h6>Стулья</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Стулья', tab: '2', itemId: 'c-2-5' } }">    
                                                <button data-item="c-2-5" data-header="Лифлеты" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-6.svg">
                                            <h6>Столы</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Столы', tab: '2', itemId: 'c-2-6' } }">    
                                                <button data-item="c-2-6" data-header="Лифлеты" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-7.svg">
                                            <h6>Диваны</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Диваны', tab: '2', itemId: 'c-2-7' } }">    
                                                <button data-item="c-2-7" data-header="Лифлеты" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-2-8.svg">
                                            <h6>Кровати</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Кровати', tab: '2', itemId: 'c-2-8' } }">    
                                                <button data-item="c-2-8" data-header="Лифлеты" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                    </div>
                                    <div data-tab="c-3" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-3-1.svg">
                                            <h6>Беседки</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Беседки', tab: '3', itemId: 'c-3-1' } }">
                                                <button data-item="c-3-1" data-header="Ручки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-3-7.svg">
                                            <h6>Навесы</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Навесы', tab: '3', itemId: 'c-3-7' } }">
                                                <button data-item="c-3-7" data-header="Винные карты" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-3-2.svg">
                                            <h6>Урны</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Урны', tab: '3', itemId: 'c-3-2' } }">
                                                <button data-item="c-3-2" data-header="Чехлы" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>

                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-3-3.svg">
                                            <h6>Вазоны</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Вазоны', tab: '3', itemId: 'c-3-3' } }">
                                                <button data-item="c-3-3" data-header="Кружки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-3-4.svg">
                                            <h6>Скамейки
                                                
                                            </h6>
                                            <br class="d-xl-none">
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Скамейки', tab: '3', itemId: 'c-3-4' } }">
                                                <button data-item="c-3-4" data-header="Флешки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-3-5.svg">
                                            <h6>Посты <br class="mobile">охраны </h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Посты охраны', tab: '3', itemId: 'c-3-5' } }">
                                                <button data-item="c-3-5" data-header="Бейджи" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-3-6.svg">
                                            <h6>Детский <br class="mobile">комплекс </h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Детский комплекс', tab: '3', itemId: 'c-3-6' } }">
                                                <button data-item="c-3-6" data-header="Бейджи" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                    </div>
                                    <div data-tab="c-4" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-4-4.svg">
                                            <h6>Производства трибун <br class="d-none d-md-block">для зрителей</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Производсва трибун для зрителей', tab: '4', itemId: 'c-4-4' }}">
                                                <button data-item="c-4-4" data-header="Фирменные бланки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>

                                            </router-link>
                                        </div>
                                        <div class="col-5 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-4-3.svg">
                                            <h6>Производство трибун <br class="d-none d-sm-block">с навесом</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Производство трибун с навесом', tab: '4', itemId: 'c-4-3' } }">
                                                <button data-item="c-4-3" data-header="Производство трибун с навесом" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-4-1.svg">
                                            <h6>Производство трибун <br>скамеечного типа</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Производство трибун скамеечного типа', tab: '4', itemId: 'c-4-1' } }">
                                                <button data-item="c-4-1" data-header="Производство трибун скамеечного типа" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-4-2.svg">
                                            <h6>Производство трибун пластиковыми сиденьями</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Производство трибун пластиковыми сиденьями', tab: '4', itemId: 'c-4-2' } }">
                                                <button data-item="c-4-2" data-header="Производство трибун пластиковыми сиденьями" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                    </div>
                                    <div data-tab="c-5" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-5-1.svg">
                                            <h6>Спортивные <br>ограждение</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Спортивные ограждение', tab: '5', itemId: 'c-5-1' } }">
                                                <button data-item="c-5-1" data-header="Спортивные ограждение" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-5-2.svg">
                                            <h6>Забор из <br>профнастила</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Забор из профнастила', tab: '5', itemId: 'c-5-2' } }">
                                                <button data-item="c-5-2" data-header="Забор из профнастила" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-5-3.svg">
                                            <h6>Уличные <br>ограждение</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Уличные ограждение', tab: '5', itemId: 'c-5-3' } }">
                                                <button data-item="c-5-3" data-header="Уличные ограждение" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-5-4.svg">
                                            <h6>Ворота<br>и решетки</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Ворота и решетки', tab: '5', itemId: 'c-5-4' } }">
                                                <button data-item="c-5-4" data-header="Ворота и решетки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                    </div>
                                    <div data-tab="c-6" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-6-1.svg">
                                            <h6>Реставрация футбольных ворот </h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Реставрация футбольных ворот', tab: '6', itemId: 'c-6-1' } }">
                                                <button data-item="c-6-1" data-header="Меню" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-6-2.svg">
                                            <h6>Покраска порошковой краской</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Покраска порошковой краской', tab: '6', itemId: 'c-6-2' } }">
                                                <button data-item="c-6-2" data-header="Чеки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-6-3.svg">
                                            <h6>Услуги <br>трубогиба</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Услуги трубогиба', tab: '6', itemId: 'c-6-3' } }">
                                                <button data-item="c-6-3" data-header="Чеки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-6-4.svg">
                                            <h6>Сварочные <br>работы</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Сварочные  работы', tab: '6', itemId: 'c-6-4' } }">
                                                <button data-item="c-6-4" data-header="Сварочные  работы" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>                                    
                                    </div>
                                    <div data-tab="c-7" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-7-1.svg">
                                            <h6>Ветеринарное <br>оборудование</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Ветеринарное оборудование', tab: '7', itemId: 'c-7-1' } }">
                                                <button data-item="c-7-1" data-header="Открытки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                    </div>
                                    <div data-tab="c-8" class="catalog-subcategory-tab">
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-8-1.svg">
                                            <h6>Освещения</h6>
                                            <br class="d-none d-lg-block">
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Освещения', tab: '8', itemId: 'c-8-1' } }">
                                                <button data-item="c-8-1" data-header="Открытки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-8-2.svg">
                                            <h6>Декор</h6>
                                            <br class="d-none d-lg-block">
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Декор', tab: '8', itemId: 'c-8-2' } }">
                                                <button data-item="c-8-2" data-header="Открытки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-8-3.svg">
                                            <h6>Ванная<br>комната</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Ванная комната', tab: '8', itemId: 'c-8-3' } }">
                                                <button data-item="c-8-3" data-header="Открытки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                        <div class="col-6 col-lg-3 catalog-subcategory-item">
                                            <img src="img/c-8-4.svg">
                                            <h6>Настенные <br>полки</h6>
                                            <router-link :to="{ name: 'Order', query: { fromHome: true, itemTitle: 'Настенные полки', tab: '8', itemId: 'c-8-4' } }">
                                                <button data-item="c-8-4" data-header="Открытки" data-image="" class="c-s-button orange-button">ЗАКАЗАТЬ</button>
                                            </router-link> 
                                        </div>
                                    </div>                                                
            </div>
        </div>
  </div>
</template>



<script>
export default {
  name: "home",
  methods: {
    menuClick(index) {
      $('html, body').animate({
        scrollTop: $(index).offset().top
      }, 1500);
    },
    setFormDescr(text) {
      $('#form-descr').text(text);
      $('#hid-mwform').val(text);
    },
    setFormDescrQ(textDescr, textHead) {
      $('#form-descr').text(textDescr);
      $('#form-head').text(textHead);
      $('#hid-mwform').val(textHead);
    }
    
  },
  mounted: function() {
        if(this.$route.query.fromOrder == true) {
            $('html, body').animate({
              scrollTop: $("#catalog").offset().top
            }, 1);
            $('body').css({"overflow-y":"hidden"});
            $('.modal-shadow').fadeIn();
            $('.modal-window-products').fadeIn();
            var buttonData = this.$route.query.toTab;
            var categoryName = this.$route.query.toTabHeader;
            $('.catalog-subcategory-tab').css('display','none');
            $("div[data-tab='" + buttonData +"']").css('display','flex');
            $('#modal-window-products-header').text(categoryName);
        };
        $('.menu-link').click(function(){
            $('.menu-link').removeClass('menu-link_active');
            $('.menu-link').addClass('menu-link_disabled');
            $(this).removeClass('menu-link_disabled');
            $(this).addClass('menu-link_active');
        });
        $(".c-button-1").click(function(){
            var buttonData = $(this).attr("data-tab");
            var categoryName = $(this).attr("data-header");
            $('.catalog-subcategory-tab').css('display','none');
            $("div[data-tab='" + buttonData +"']").css('display','flex');
            $('#modal-window-products-header').text(categoryName);
        });
        $('.portfolio-menu__item').click(function(){
            $('.portfolio-menu__item').removeClass('portfolio-menu__item_active');
            $('.portfolio-menu__item').addClass('portfolio-menu__item_disabled');
            $(this).removeClass('portfolio-menu__item_disabled');
            $(this).addClass('portfolio-menu__item_active');
        });
        $('#portfolio-tab-1-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-1').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-tab-2-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-2').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-tab-3-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-3').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-tab-4-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-4').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-tab-5-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-5').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-tab-6-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-6').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-tab-7-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-7').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-tab-8-button').click(function(){
            $('.portfolio-tab').css('display','none');
            $('#portfolio-tab-8').css('display','block');
            $('.portfolio-slider').slick('slickNext');
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#reviews-slider-prev').click(function(){
            $('.reviews-slider').slick('slickPrev');
        });
        $('#reviews-slider-next').click(function(){
            $('.reviews-slider').slick('slickNext');
        });
        $('#portfolio-slider-prev').click(function(){
            $('.portfolio-slider').slick('slickPrev');
        });
        $('#portfolio-slider-next').click(function(){
            $('.portfolio-slider').slick('slickNext');
        });
        $('#advs-slider-prev').click(function(){
            $('.advs-slider').slick('slickPrev');
        });
        $('#advs-slider-next').click(function(){
            $('.advs-slider').slick('slickNext');
        });
        $(function(){
          $('.formbut-mw').click(function () {
            $('body').css({"overflow-y":"hidden"});
            $('.success_message').hide();
            $('.error_message').hide();
            $('.modal-shadow').fadeIn();
            $('.modal-window-form').fadeIn();
          });
          $('.c-button-1').click(function () {
            $('body').css({"overflow-y":"hidden"});
            $('.modal-shadow').fadeIn();
            $('.modal-window-products').fadeIn();
          });
          $('.modal-shadow').click(function () {
            $('body').css({"overflow-y":"scroll"})
            $('.modal-shadow').fadeOut();
            $('.success_message').hide();
            $('.error_message').hide();
            $('.modal-window-form').fadeOut();
            $('.modal-window-products').fadeOut();
          });
          $('.close-button').click(function () {
            $('body').css({"overflow-y":"scroll"})
            $('.modal-shadow').fadeOut();
            $('.success_message').hide();
            $('.error_message').hide();
            $('.modal-window-form').fadeOut();
            $('.modal-window-products').fadeOut();
          });
          $('.c-s-button').click(function () {
            $('body').css({"overflow-y":"scroll"})
            $('.modal-shadow').fadeOut();
            $('.success_message').hide();
            $('.error_message').hide();
            $('.modal-window-form').fadeOut();
            $('.modal-window-products').fadeOut();
          });
        });

        var flag1 = false;
        var flag2 = false;
        var flag3 = false;
        var flag4 = false;
        var flags = [false, false, false, false, false];
        function openbox(f,a,mp,q){
            $(a).slideToggle("normal");
            if (flags[f] == false) {
                flags[f] = true;
                document.getElementById(mp).style.transform = "rotateZ(0deg)";
            } else {
                flags[f] = false;
                document.getElementById(mp).style.transform = "rotateZ(180deg)";
            }
        }
        document.getElementById("q1").onclick = function(){
            openbox(0,"#a1","arr1","q1");
            if (flag1 == false) {
                flag1 = true
            } else {
                flag1 = false
            }
            if (flag2 == true) {openbox(1,"#a2","arr2","q2");flag2 = false}
            if (flag3 == true) {openbox(2,"#a3","arr3","q3");flag3 = false}
            if (flag4 == true) {openbox(3,"#a4","arr4","q4");flag4 = false}
        }
        document.getElementById("q2").onclick = function(){
            openbox(1,"#a2","arr2","q2");
            if (flag2 == false) {
                flag2 = true
            } else {
                flag2 = false
            }
            if (flag1 == true) {openbox(0,"#a1","arr1","q1");flag1 = false}
            if (flag3 == true) {openbox(2,"#a3","arr3","q3");flag3 = false}
            if (flag4 == true) {openbox(3,"#a4","arr4","q4");flag4 = false}
        }
        document.getElementById("q3").onclick = function(){
            openbox(2,"#a3","arr3","q3");
            if (flag3 == false) {
                flag3 = true
            } else {
                flag3 = false
            }
            if (flag2 == true) {openbox(1,"#a2","arr2","q2");flag2 = false}
            if (flag1 == true) {openbox(0,"#a1","arr1","q1");flag1 = false}
            if (flag4 == true) {openbox(3,"#a4","arr4","q4");flag4 = false}
        }
        document.getElementById("q4").onclick = function(){
            openbox(3,"#a4","arr4","q4");
            if (flag4 == false) {
                flag4 = true
            } else {
                flag4 = false
            }
            if (flag2 == true) {openbox(1,"#a2","arr2","q2");flag2 = false}
            if (flag3 == true) {openbox(2,"#a3","arr3","q3");flag3 = false}
            if (flag1 == true) {openbox(0,"#a1","arr1","q1");flag1 = false}
        }

        $(document).ready(function(){
            $('.portfolio-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 3500
            });
            $('.intro-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000
            });
            $('.advs-slider').slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 3500,
                responsive: [
                    {
                      breakpoint: 450,
                      settings: {
                        slidesToShow: 1
                      }
                    }
                ]
            });
            $('.reviews-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                infinite: false
            });
        });
            $(function(){ 
                function after_form_submitted(data) 
                {
                    if(data.result == 'success')
                    {
                        //$('form#reused_form').hide();
                        $('.success_message').show();
                        $('.error_message').hide();

                        $('#name-mwform').val('');
                        $('#phone-mwform').val('');
                        $('#email-mwform').val('');
                        $('#question-mwform').val('');
                        $('#name-appform').val('');
                        $('#phone-appform').val('');
                        $('#email-appform').val('');
                        $('#question-appform').val('');
                    }
                    else
                    {
                        $('.error_message').append('<ul></ul>');

                        jQuery.each(data.errors,function(key,val)
                        {
                            $('.error_message ul').append('<li>'+key+':'+val+'</li>');
                        });
                        $('.success_message').hide();
                        $('.error_message').show();

                        

                        //reverse the response on the button
                        /*$('button[type="button"]', $form).each(function()
                        {
                            $btn = $(this);
                            label = $btn.prop('orig_label');
                            if(label)
                            {
                                $btn.prop('type','submit' ); 
                                $btn.text(label);
                                $btn.prop('orig_label','');
                            }
                        });*/
                        
                    }//else
                }

                $('.contact-form').submit(function(e)
                  {
                    e.preventDefault();

                    var form = $(this);
                    //show some response on the button
                

                                $.ajax({
                            type: "POST",
                            url: 'mail2/handler.php',
                            data: $(form).serialize(),
                            success: after_form_submitted,
                            dataType: 'json' 
                        });        
                    
                  });
                });  
            
  }

};
    
</script>
