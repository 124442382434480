import Vue from 'vue'
import VueFullPage from 'vue-fullpage.js'
import App from './App.vue'
import router from './router'


Vue.use(VueFullPage)

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})






